import { Box, BoxProps } from '@mui/material'
import { keyframes } from '@emotion/react'
import React from 'react'

interface RedBarProps extends Pick<BoxProps, 'sx'> {
  rotated?: boolean
  size?: 'm' | 'l'
  invert?: boolean
}

// const gradientLoop = keyframes`
// 0% {
//   background-position: 0% 0%;
// }
// 50% {
//   background-position: 100% 100%;
// }
// 100% {
//   background-position: 0% 0%;

// }
// `
const heightLoop = keyframes`
0% {
  transform: scaleY(1.75);
}
50% {
  transform: scaleY(0.75);
}
100% {
  transform: scaleY(1.75);
}
`
const widthLoop = keyframes`
0% {
  transform: scaleX(1);
}
50% {
  transform: scaleX(0.5);
}
100% {
  transform: scaleX(1);
}
`

export const RedBar = ({
  sx,
  rotated,
  size = 'l',
  invert = false,
}: RedBarProps) => (
  <Box
    sx={{
      width: rotated ? 8 : size === 'l' ? 250 : 170,
      height: rotated ? (size === 'l' ? 250 : 170) : 8,
      mt: 1.25,
      backgroundPosition: '50% 50%',
      backgroundSize: '100% 100%',
      backgroundImage: invert
        ? `linear-gradient(${
            rotated ? 'to top' : 'to left'
          }, rgba(255, 255, 255, 0)0%, rgba(255, 255, 255, 1) 100%)`
        : `linear-gradient(${
            rotated ? 'to top' : 'to left'
          }, rgba(0, 0, 0, 0)0%, rgba(255, 40, 67, 1) 100%)`,
      animation: `${rotated ? heightLoop : widthLoop} ease-in-out ${
        size === 'l' ? '4.5' : '3.5'
      }s infinite`,
      ...sx,
    }}
  />
)
