import { Box, Typography } from '@mui/material'
import { motion, Variants } from 'framer-motion'
import ReactMarkdown from 'react-markdown'
import { Content } from '../Item/subcomponents'

export interface HeroProps {
  mediaUrl?: string
  top: string
  bottom?: string | null
  dark: boolean
}

const imgVariants: Variants = {
  visible: {
    scale: 1.5,
    transition: {
      duration: 30,
      repeat: 'Infinity',
      repeatType: 'mirror',
    } as any,
  },
}

export const Hero = ({ mediaUrl, top, bottom, dark }: HeroProps) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        inset: '0 0 0 0',
        img: { objectFit: 'cover', width: '100%', height: '100%' },
      }}
    >
      <Box
        component={motion.div}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          ':after': {
            content: '""',
            position: 'absolute',
            inset: '0 0 0 0',
            // background: 'rgb(2,0,36)',
            background: dark
              ? 'transparent'
              : 'linear-gradient(180deg, rgba(43,11,15,0.8267900910364145) 0%, rgba(0,0,0,0.16292454481792717) 49%, rgba(0,0,0,0) 100%)',
          },
        }}
        variants={imgVariants}
        animate="visible"
      >
        <img src={`${process.env.REACT_APP_CMS}${mediaUrl}`} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          inset: '50px 50px 50px 50px',
          border: '3px solid',
          borderColor: dark ? 'primary.dark' : 'common.white',
          color: dark ? 'primary.dark' : 'common.white',
          overflow: 'hidden',
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
          h1: {
            typography: 'h1',
            fontSize: '22rem !important',
            lineHeight: '19rem !important',
            letterSpacing: 'none',
            fontWeight: 800,
          },
          h2: {
            typography: 'h2',
            fontSize: '16rem !important',
            lineHeight: '14rem !important',
          },
          h3: {
            typography: 'h3',
            fontSize: '11rem !important',
            lineHeight: '10.5rem !important',
          },
          h4: {
            typography: 'h4',
            lineHeight: '5.5rem !important',
          },
          h5: {
            typography: 'h5',
          },
          h6: {
            typography: 'h6',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 100,
            left: '50%',
            width: '85%',
            maxWidth: '85%',
            color: dark ? 'primary.dark' : 'common.white',
            transform: 'translateX(-50%)',
            wordBreak: 'break-word',
          }}
          component={motion.div}
          initial={{ top: -900 }}
          animate={{ top: 100 }}
          transition={{ delay: 1.5, type: 'spring' }}
        >
          <ReactMarkdown children={top} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 100,
            left: '50%',
            width: '85%',
            maxWidth: '85%',
            transform: 'translateX(-50%)',
          }}
          component={motion.div}
          initial={{ bottom: -900 }}
          animate={{ bottom: 100 }}
          transition={{ delay: 2, type: 'spring' }}
        >
          {bottom && <ReactMarkdown children={bottom} />}
        </Box>
      </Box>
    </Box>
  )
}
