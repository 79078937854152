import { Box } from '@mui/material'
import { motion, Variants } from 'framer-motion'
import { Bars } from '../Item/subcomponents'

export interface VideoProps {
  mediaUrl?: string
}

const variantsUp = {
  init: { top: -350 },
  animate: { top: -1000, transition: { duration: 3, delay: 3 } },
}
const variantsDown = {
  init: { top: 800 },
  animate: { top: 1550, transition: { duration: 3, delay: 3 } },
}

export const Video = ({ mediaUrl }: VideoProps) => (
  <Box
    sx={{
      position: 'absolute',
      inset: '0 0 0 0',
      //   img: { objectFit: 'cover', width: '100%', height: '100%' },
    }}
  >
    <Bars
      sx={{
        position: 'absolute',
        right: 100,
        top: 110,
        left: 'auto',
        bottom: 'auto',
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: -1000,
        left: 0,
        right: 0,
        height: 1350,
        WebkitClipPath: 'polygon(0 0%, 100% 0, 100% 90%, 0% 100%)',
        clipPath: 'polygon(0 0%, 100% 0, 100% 90%, 0% 100%)',
        bgcolor: 'common.black',
      }}
      component={motion.div}
      variants={variantsUp}
      initial="init"
      animate="animate"
    ></Box>
    <Box
      sx={{
        position: 'absolute',
        zIndex: 2,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        img: { opacity: 0.7 },
      }}
    >
      <img src="/bombai-logo.svg" width={500} />
    </Box>
    <Box
      component={motion.div}
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        height: 1500,
        bgcolor: 'green',
        video: {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          right: 0,
          top: 0,
          objectFit: 'contain',
          objectPosition: 'center',
          overflow: 'hidden',
          height: '100%',
          //   width: 'auto',
        },
      }}
    >
      <video autoPlay muted loop playsInline>
        <source
          src={`${process.env.REACT_APP_CMS}${mediaUrl}`}
          type="video/mp4"
        />
      </video>
    </Box>
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: 1000,
        left: 0,
        right: 0,
        height: 1350,
        WebkitClipPath: 'polygon(0 10%, 100% 0, 100% 100%, 0% 100%)',
        clipPath: 'polygon(0 10%, 100% 0, 100% 100%, 0% 100%)',
        bgcolor: 'common.black',
      }}
      component={motion.div}
      variants={variantsDown}
      initial="init"
      animate="animate"
    ></Box>
    <Bars
      sx={{
        position: 'absolute',
        zIndex: 1,
        left: 100,
        bottom: 110,
        right: 'auto',
        top: 'auto',
      }}
    />
  </Box>
)
