import { Box, BoxProps, SxProps } from '@mui/material'
import { motion, Variants } from 'framer-motion'
import { ReactNode } from 'react'

export interface ScreenProps {
  children: ReactNode
  sx?: SxProps
}

const bgVariants: Variants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 2, delayChildren: 2, staggerChildren: 1 },
  },
  exit: { opacity: 0, transition: { duration: 2 } },
}

export const Screen = ({ children, sx }: ScreenProps) => (
  <Box
    sx={{
      position: 'absolute',
      inset: '0 0 0 0',
      ...sx,
    }}
    component={motion.div}
    initial="initial"
    animate="visible"
    exit="exit"
    variants={bgVariants}
  >
    {children}
  </Box>
)
