import { Box } from '@mui/material'
import { motion, Variants } from 'framer-motion'
import { Bars, CircleLabel, Content } from './subcomponents'

export interface ItemProps {
  /** background image / video url */
  mediaUrl?: string
  /** if media video
   * @default false
   */
  video?: boolean
  /** text shown inner circle */
  label: string
  /** content over overlay */
  content: string
  /**
   * dark background
   * @default true
   * */
  dark?: boolean
}

const imgVariants: Variants = {
  visible: {
    scale: 1.5,
    transition: {
      duration: 30,
      repeat: 'Infinity',
      repeatType: 'mirror',
    } as any,
  },
}

export const Item = ({
  mediaUrl,
  video = false,
  label,
  content,
  dark = true,
}: ItemProps) => (
  <Box
    sx={{
      position: 'absolute',
      inset: '0 0 0 0',
      img: { objectFit: 'cover', width: '100%', height: '100%' },
    }}
  >
    {video ? (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          video: {
            position: 'absolute',
            left: 0,
            top: 0,
            objectFit: 'contain',
            overflow: 'hidden',
            height: '100%',
            width: 'auto',
          },
        }}
      >
        <video autoPlay muted loop playsInline>
          <source src="/bombai-livingbar-video.mp4" type="video/mp4" />
        </video>
      </Box>
    ) : (
      <Box
        component={motion.div}
        sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
        variants={imgVariants}
        animate="visible"
      >
        <img src={`${process.env.REACT_APP_CMS}${mediaUrl}`} />
      </Box>
    )}

    <CircleLabel label={label} invert={!dark} />
    <Content content={content} dark={dark} />
    <Bars invert={!dark} />
  </Box>
)
