import { Box, Typography } from '@mui/material'
import { motion, Variants } from 'framer-motion'

interface CircleLabelProps {
  label: string
  /**
   * inverts color to be white
   * @default false
   */
  invert?: boolean
}

const variants: Variants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    // transition: { delay: 1 },
  },
}

export const CircleLabel = ({ label, invert = false }: CircleLabelProps) => (
  <Box
    sx={{
      position: 'absolute',
      zIndex: 2,
      left: '50%',
      top: 610,
      transform: 'translateX(-50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 350,
      height: 350,
      bgcolor: invert ? 'common.white' : 'primary.main',
      borderRadius: '50%',
    }}
    component={motion.div}
    variants={variants}
  >
    <Typography
      variant="h4"
      sx={{
        position: 'relative',
        color: invert ? 'text.main' : 'common.white',
        fontSize: ['3.5rem'],
        lineHeight: ['4rem'],
        textAlign: 'center',
        ':after': {
          content: '""',
          position: 'absolute',
          bottom: -20,
          left: '50%',
          transform: 'translateX(-50%)',
          width: 120,
          height: 5,
          bgcolor: invert ? 'text.primary' : 'common.white',
        },
      }}
    >
      {label}
    </Typography>
  </Box>
)
