import { Box } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { Card, CardProps } from './components/Card'
import { Feed } from './components/Feed'
import { Hero, HeroProps } from './components/Hero'
import { Item, ItemProps } from './components/Item/Item'
import { Screen } from './components/Screen'
import { Video, VideoProps } from './components/Video'
import { useDigitalSignageQuery } from './api'

const SCREENS_QUERY = gql`
  query DigitalSignage {
    digitalSignage {
      data {
        attributes {
          screens {
            __typename
            ... on ComponentScreensVideo {
              screenTitle
              active
              video {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentScreensCard {
              screenTitle
              active
              title
              media {
                data {
                  attributes {
                    url
                  }
                }
              }
              cardItems {
                id
                title
                subtitle
                price
              }
            }
            ... on ComponentScreensItem {
              screenTitle
              active
              label
              media {
                data {
                  attributes {
                    url
                  }
                }
              }
              dark
              content
            }
            ... on ComponentScreensInsta {
              screenTitle
              active
              token
            }
            ... on ComponentScreensHero {
              screenTitle
              active
              media {
                data {
                  attributes {
                    url
                  }
                }
              }
              top
              bottom
              dark
            }
          }
        }
      }
    }
  }
`

const contentMock1 =
  '##### Happy Hour \n\n ## Cocktails\n# 5€\n\n##### JEDEN TAG\n\n#### BIS 23 UHR\n'
const contentMock2 =
  '### Geburtstag feiern \n\n ## Cocktails\n# 5€\n\n##### JEDEN TAG\n\n'
const contentMock3 = '# 3.50€\n #### Pfirsich oder Zitrone mit Früchten\n '
const contentMock4 =
  '# 3.50€\n #### Auch mit Vanille, Karamell oder Kokosgeschmack\n '

type ItemScreen = ItemProps & { type: 'item' }
type InstaScreen = { type: 'insta_feed' }
type CardScreen = CardProps & { type: 'card' }
type VideoScreen = VideoProps & { type: 'video' }
type HeroScreen = HeroProps & { type: 'hero' }

const SCREENS: Array<
  ItemScreen | InstaScreen | CardScreen | VideoScreen | HeroScreen
> = [
  {
    type: 'item',
    content: contentMock1,
    label: 'angebot',
    mediaUrl: '/bombai-livingbar-location-6.webp',
    dark: false,
  },
  {
    type: 'hero',
    mediaUrl: '/bombai-livingbar-location-7.webp',
    top: 'Latte Macchiato',
    bottom: contentMock4,
    dark: true,
  },
  {
    type: 'video',
    mediaUrl: '/bombai-livingbar-video.mp4',
  },
  {
    type: 'card',
    title: 'Bombai Specials',
    cardItems: [
      { id: '1', title: 'Pina Colada', price: 6.5 },
      {
        id: '2',
        title: 'Sex on the beach',
        subtitle: 'Lorem ipsum dolor amet la ispum delir to',
        price: 7.5,
      },
      { id: '3', title: 'Jason the killer', price: 7.5 },
      { id: '4', title: 'Caipirinha', price: 7.5 },
      { id: '5', title: 'Long island ice tea', price: 9.5 },
    ],
    mediaUrl: '/ds-mock1.webp',
  },
  {
    type: 'item',
    content: contentMock1,
    label: 'fussball',
    mediaUrl: '/bombai-livingbar-fussball.jpg',
    dark: true,
  },
  {
    type: 'hero',
    mediaUrl: '/bombai-livingbar-location-9.webp',
    top: 'Hausgemachter Eistee',
    bottom: contentMock3,
    dark: true,
  },
  { type: 'insta_feed' },
  {
    type: 'hero',
    mediaUrl: '/ds-mock1.webp',
    top: 'Belvedere Vodka',
    bottom: contentMock3,
    dark: false,
  },
  {
    type: 'item',
    content: contentMock2,
    label: 'cocktails',
    mediaUrl: '/bombai-livingbar-location-7.webp',
    dark: true,
  },
]

const DEFAULT_INTERVAL = 180000

function App() {
  const queryParams = new URLSearchParams(window.location.search)
  const pollInterval = queryParams.get('pollInterval')
  const screenInterval = queryParams.get('screenInterval')
  console.log('PollInterval: ', pollInterval)
  console.log('ScreenInterval: ', screenInterval)

  const [visibleIndex, setVisibleIndex] = useState(0)
  const { data } = useDigitalSignageQuery({
    pollInterval: pollInterval ? +pollInterval : DEFAULT_INTERVAL,
  })

  const screensData = data?.digitalSignage?.data?.attributes?.screens
  const screens = screensData
    ? screensData.filter(s =>
        s && s.__typename !== 'Error' ? s.active : false,
      )
    : null

  useEffect(() => {
    if (screens) {
      const interval = setInterval(
        () => setVisibleIndex(v => (v === screens.length - 1 ? 0 : v + 1)),
        screenInterval ? +screenInterval : DEFAULT_INTERVAL,
      )
      return () => clearInterval(interval)
    }
  }, [screens])

  if (!screens) {
    return null
  }

  return (
    <Box
      className="app"
      sx={{ width: '100%', height: '100%' }}
      onClick={() =>
        setVisibleIndex(v => (v === screens.length - 1 ? 0 : v + 1))
      }
    >
      <AnimatePresence exitBeforeEnter>
        {screens.map(
          (screen, i) =>
            visibleIndex === i && (
              <Screen key={i}>
                {(() => {
                  switch (screen?.__typename) {
                    case 'ComponentScreensItem':
                      return (
                        <Item
                          label={screen.label}
                          dark={screen.dark}
                          content={screen.content}
                          mediaUrl={screen.media.data?.attributes?.url}
                        />
                      )
                    case 'ComponentScreensInsta':
                      return <Feed token={screen.token} />
                    case 'ComponentScreensCard':
                      return (
                        <Card
                          title={screen.title}
                          cardItems={screen.cardItems}
                          mediaUrl={screen.media.data?.attributes?.url}
                        />
                      )
                    case 'ComponentScreensVideo':
                      return (
                        <Video mediaUrl={screen.video.data?.attributes?.url} />
                      )
                    case 'ComponentScreensHero':
                      return (
                        <Hero
                          mediaUrl={screen.media.data?.attributes?.url}
                          top={screen.top}
                          bottom={screen.bottom}
                          dark={screen.dark}
                        />
                      )
                    default:
                      return null
                  }
                })()}
              </Screen>
            ),
        )}
      </AnimatePresence>
    </Box>
  )
}

export default App
