import { Box, SxProps } from '@mui/material'
import { motion, Variants } from 'framer-motion'
import { RedBar } from './RedBar'

const barsVariants: Variants = {
  initial: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 2 } },
  exit: { opacity: 0 },
}

export const Bars = ({
  sx,
  invert = false,
}: {
  sx?: SxProps
  invert?: boolean
}) => (
  <Box
    component={motion.div}
    data-testid="bars"
    sx={{
      position: 'absolute',
      zIndex: 2,
      left: 100,
      top: 820,
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      ...sx,
    }}
    variants={barsVariants}
  >
    <RedBar invert={invert} rotated size="m" />
    <RedBar invert={invert} rotated />
  </Box>
)
