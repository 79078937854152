import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import { motion, Variants } from 'framer-motion'
import { RedBar } from './RedBar'

const overlayVariants: Variants = {
  initial: { y: 1600 },
  visible: {
    y: 270,
    transition: { stiffness: 100, type: 'spring' },
  },
  exit: {
    y: 1600,
    transition: { duration: 1 },
  },
}

export const Content = ({
  content,
  dark,
}: {
  content: string
  dark?: boolean
}) => (
  <Box
    component={motion.div}
    data-testid="content"
    sx={{
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 2,
      height: 1600,
      pt: 8,
      px: 10,
      bgcolor: dark ? 'black' : 'primary.main',
      color: 'common.white',
      WebkitClipPath: 'polygon(0 25%, 100% 0, 100% 100%, 0% 100%)',
      clipPath: 'polygon(0 25%, 100% 0, 100% 130%, 0% 130%)',
      overflow: 'hidden',
      whiteSpace: 'pre-line',
      textAlign: 'center',
      h1: {
        typography: 'h1',
        fontSize: '23rem !important',
        lineHeight: '22rem !important',
      },
      h2: {
        typography: 'h2',
      },
      h3: {
        typography: 'h3',
      },
      h4: {
        typography: 'h4',
      },
      h5: {
        typography: 'h5',
      },
      h6: {
        typography: 'h6',
      },
    }}
    // initial="initial"
    // animate="slideUp"
    // exit="exit"
    variants={overlayVariants}
  >
    <ReactMarkdown children={content} />
    <RedBar invert={!dark} sx={{ mt: 6 }} />
    {/* <PinkCircle
      sx={{
        position: 'absolute',
        zIndex: 1,
        right: -700,
        bottom: -500,
        width: 1600,
        height: 1600,
        opacity: 0.5,
      }}
    /> */}
  </Box>
)
