import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff2843',
    },
    secondary: {
      main: '#1b1b1b',
    },
    error: {
      main: '#ff2843',
    },
  },
})

theme.typography = {
  ...theme.typography,
  h1: {
    margin: 0,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '3.5rem',
    fontWeight: 700,
    lineHeight: '4rem',
    hyphens: 'auto',
    textTransform: 'uppercase',
    letterSpacing: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20rem',
      lineHeight: '20rem',
    },
  },
  h2: {
    margin: 0,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '2.5rem',
    fontWeight: 700,
    lineHeight: '3.5rem',
    hyphens: 'auto',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: '10rem',
      lineHeight: '10rem',
    },
  },
  h3: {
    margin: 0,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: '3.5rem',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: '6rem',
      lineHeight: '6rem',
    },
  },
  h4: {
    margin: 0,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.25rem',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: '4.5rem',
      lineHeight: '4.5rem',
    },
  },
  h5: {
    margin: 0,
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: '1rem',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
      lineHeight: '3rem',
    },
  },
  h6: {
    margin: 0,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
      lineHeight: '2rem',
    },
  },
  body1: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.75rem',
    hyphens: 'auto',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      lineHeight: '1.875rem',
    },
  },
  body2: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    whiteSpace: 'pre-line',
  },
}

export default theme
