import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'

export interface PostProps extends BoxProps {
  id?: string
  title?: string
  caption?: string
  media_type: 'IMAGE' | 'VIDEO' | 'CAROUSEL_ALBUM'
  media_url: string
  thumbnail_url?: string
  timestamp?: string
  permalink?: string
  compact?: boolean
}

export const Post = ({
  id,
  title,
  media_type,
  media_url,
  compact = false,
  sx,
  ...boxProps
}: PostProps) => (
  <Box
    className="mediaWrapper"
    sx={{
      position: 'relative',
      width: compact ? 500 : 690,
      height: compact ? 500 : 690,
      overflow: 'hidden',
      img: {
        objectFit: 'cover',
        objectPosition: 'center',
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        height: '100%',
      },
      video: {
        objectFit: 'cover',
        objectPosition: 'center',
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        height: '100%',
      },
      ':after': compact
        ? {
            content: '""',
            position: 'absolute',
            inset: '0 0 0 0',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
          }
        : null,
      ...sx,
    }}
    {...boxProps}
  >
    {media_type === 'IMAGE' && <img src={media_url} />}
    {media_type === 'VIDEO' && (
      <video width="100%" height="100%" autoPlay playsInline muted loop>
        <source src={media_url} type="video/mp4" />
      </video>
    )}
  </Box>
)
