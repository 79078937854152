import { Box, Typography } from '@mui/material'
import { motion, Variants } from 'framer-motion'
import { ComponentScreensCard } from '../../api'
import { Bars } from '../Item/subcomponents'

export interface CardItem {
  title: string
  subtitle?: string
  price?: number
}

export interface CardProps {
  /** card title */
  title: string
  /** background image / video url */
  mediaUrl?: string
  /** card items to display in list */
  cardItems: ComponentScreensCard['cardItems']
}

const imgVariants: Variants = {
  visible: {
    scale: 1.5,
    transition: {
      duration: 30,
      repeat: 'Infinity',
      repeatType: 'mirror',
    } as any,
  },
}

const bgVariants: Variants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1, delay: 2, delayChildren: 2, staggerChildren: 1 },
  },
}

const getVariants = (delay: number): Variants => ({
  initial: {
    left: 1000,
  },
  visible: {
    left: 0,
    transition: { delay },
  },
})

export const Card = ({ cardItems, mediaUrl, title }: CardProps) => (
  <Box
    sx={{
      position: 'absolute',
      inset: '0 0 0 0',
      img: { objectFit: 'cover', width: '100%', height: '100%' },
    }}
  >
    <Box
      component={motion.div}
      sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
      variants={imgVariants}
      animate="visible"
    >
      <img src={`${process.env.REACT_APP_CMS}${mediaUrl}`} />
    </Box>
    <Box
      component={motion.ul}
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        width: '90%',
        p: 8,
        bgcolor: 'rgba(0, 0, 0, 0.6)',
        transform: 'translate(-50%, -50%)',
        'li + li': {
          mt: 10,
        },
      }}
      variants={bgVariants}
      initial="initial"
      animate="visible"
    >
      <Bars sx={{ position: 'absolute', left: 'auto', right: 60, top: -120 }} />
      <Typography
        variant="h2"
        sx={{
          pb: 4,
          mb: 10,
          color: 'common.white',
          borderBottom: '7px solid',
          borderColor: 'primary.main',
        }}
      >
        {title}
      </Typography>
      {cardItems &&
        cardItems.map((item, i) =>
          item ? (
            <Box
              key={i}
              component={motion.li}
              variants={getVariants(3 + i * 0.3)}
              initial="initial"
              animate="visible"
              sx={{
                display: 'flex',
                position: 'relative',
                justifyContent: 'space-between',
                gap: 10,
                color: 'common.white',
                h4: {
                  fontSize: '4.5rem',
                },
              }}
            >
              <Box>
                <Typography variant="h4">{item.title}</Typography>
                {item.subtitle && (
                  <Typography
                    variant="h6"
                    sx={{ mt: 1, textTransform: 'none', opacity: 0.7 }}
                  >
                    {item.subtitle}
                  </Typography>
                )}
              </Box>
              {item.price && (
                <Typography variant="h4">{`${item.price.toFixed(
                  2,
                )}€`}</Typography>
              )}
            </Box>
          ) : null,
        )}
    </Box>
  </Box>
)
