import { Box, CircularProgress, Typography } from '@mui/material'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Bars } from '../Item/subcomponents'
import { PinkCircle } from '../Item/subcomponents/PinkCircle'
import { RedBar } from '../Item/subcomponents/RedBar'
import { Placeholder } from './subcomponents/Placeholder'
import { Post, PostProps } from './subcomponents/Post'

const bgVariants: Variants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
  exit: { opacity: 0, transition: { duration: 1 } },
}

const FALLBACK_DATA = [
  { src: '/logo-white.png' },
  { src: '/icon-insta.svg' },
  { src: '/qr-code.png' },
]

export const Feed = ({ token }: { token?: string | null }) => {
  const [visibleIndex, setVisibleIndex] = useState(0)
  const [postsData, setPostsData] = useState<Array<PostProps>>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true)
        const res = await fetch(
          `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,timestamp,permalink,thumbnail_url&access_token=${token}`,
        )
        console.log('R: ', res)
        const posts = await res.json()
        console.log('P: ', posts)
        setPostsData(posts.data.slice(0, 10))
      } catch (err) {
        setError(true)
        console.error('Insta error: ', err)
      } finally {
        setLoading(false)
      }
    }
    fetchPosts()
  }, [])

  useEffect(() => {
    console.log('PDATA: ', postsData)
    const interval = setInterval(() => {
      if (postsData.length > 0) {
        setVisibleIndex(v => (v === postsData.length - 1 ? 0 : v + 1))
      } else {
        setVisibleIndex(v => (v === FALLBACK_DATA.length - 1 ? 0 : v + 1))
      }
    }, 15000)
    return () => clearInterval(interval)
  }, [postsData])

  if (loading) {
    return <CircularProgress />
  }

  console.log('VIS INDEX: ', visibleIndex)

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Bars sx={{ position: 'absolute', right: 200, top: -70 }} />
      <Box sx={{ position: 'absolute', top: 200 }}>
        <img src="/bombai-logo.svg" width={370} />
      </Box>
      <AnimatePresence exitBeforeEnter>
        {postsData.length > 0
          ? postsData.map(
              ({ media_type, media_url }, i) =>
                visibleIndex === i && (
                  <Box
                    key={i}
                    component={motion.div}
                    variants={bgVariants}
                    initial="initial"
                    animate="visible"
                    exit="exit"
                    sx={{
                      position: 'absolute',
                      zIndex: 2,
                      top: '50%',
                      left: '50%',
                      height: 690,
                      width: 2000,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 10,
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {i > 0 ? (
                      <Post
                        media_type={postsData[i - 1].media_type}
                        media_url={postsData[i - 1].media_url}
                        compact
                      />
                    ) : (
                      <Placeholder />
                    )}
                    <Post media_type={media_type} media_url={media_url} />
                    {i < postsData.length - 1 ? (
                      <Post
                        media_type={postsData[i + 1].media_type}
                        media_url={postsData[i + 1].media_url}
                        compact
                      />
                    ) : (
                      <Placeholder />
                    )}
                  </Box>
                ),
            )
          : FALLBACK_DATA.map(
              ({ src }, i) =>
                visibleIndex === i && (
                  <Box
                    key={i}
                    component={motion.div}
                    variants={bgVariants}
                    initial="initial"
                    animate="visible"
                    exit="exit"
                    data-testid="fallback-img"
                    sx={{
                      position: 'absolute',
                      zIndex: 2,
                      left: '50%',
                      top: '50%',
                      width: 600,
                      height: 600,
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <img src={src} width={600} height={600} />
                  </Box>
                ),
            )}
      </AnimatePresence>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 2,
          bottom: 200,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'common.white',
        }}
        component={motion.div}
      >
        <Typography variant="h2">Folge uns</Typography>
        <Typography sx={{ mt: 3 }} variant="h5">
          Auf Instagram
        </Typography>
        <RedBar sx={{ mt: 6 }} />
      </Box>
      <PinkCircle
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: -900,
          bottom: -700,
          width: 2200,
          height: 2200,
          opacity: 0.2,
        }}
      />
      <PinkCircle
        sx={{
          position: 'absolute',
          //   zIndex: 1,
          left: -800,
          top: 500,
          width: 1800,
          height: 1800,
          opacity: 0.2,
        }}
      />
    </Box>
  )
}
