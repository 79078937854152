import { Box, BoxProps } from '@mui/material'

export const PinkCircle = ({ sx, ...boxProps }: BoxProps) => (
  <Box
    sx={{
      backgroundImage: 'radial-gradient(circle at 43% 60%, #ea0db1, rgba(230, 12, 182, 0) 54%)',
      ...sx,
    }}
    {...boxProps}
  />
)
